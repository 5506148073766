<template>
  <section
    v-editable="blok"
    class="relative grid w-full grid-cols-1 items-center gap-8 tablet:grid-cols-2 desktop:grid-cols-8"
    v-bind="sectionId ? { id: sectionId } : {}"
  >
    <sb-image
      v-if="blok.image?.filename"
      :blok="blok.image"
      :container-class="`mx-auto desktop:col-span-3 ${blok.reverse ? 'tablet:order-2' : ''}`"
      width="300"
      height="300"
      fit-inside
      decorative
      class="rounded"
    />
    <div class="grid grid-cols-1 items-start gap-2 desktop:col-span-5" :class="{ 'tablet:col-span-2': !blok.image?.filename }">
      <h3 v-if="blok.headline" class="text-2xl font-bold">
        {{ blok.headline }}
      </h3>
      <div class="inline-images prose prose-lg whitespace-pre-wrap dark:prose-invert" v-html="text" />
      <div v-if="blok.buttonLink && blok.buttonText" class="mt-6 w-full">
        <sb-link :link="blok.buttonLink" class="button inline-flex">
          {{ blok.buttonText }}
          <nuxt-icon name="chevron-right" class="ml-auto pl-8" filled />
        </sb-link>
      </div>
    </div>
    <span
      v-if="isEditor && sectionId"
      class="absolute left-0 top-2 hidden max-w-60 -translate-y-8 truncate text-sm opacity-50 tablet:inline-block"
    >
      #{{ sectionId }}
    </span>
  </section>
</template>

<script setup lang="ts">
import type { TeaserStoryblok } from "~/storyblok-component-types"
import { isEditorKey } from "~/injection-symbols"

const props = defineProps<{ blok: TeaserStoryblok; }>()
const isEditor = inject(isEditorKey, false)

const text = renderRTF(props.blok?.text)
const sectionId = computed(() =>
  props.blok.anchor
    ?.toLowerCase()
    .replaceAll("ä", "ae")
    .replaceAll("ö", "oe")
    .replaceAll("ü", "ue")
    .replaceAll("ß", "ss")
    .replaceAll(/[^a-z1-9-+ ]/g, "")
    .replaceAll(" ", "-"),
)
</script>
